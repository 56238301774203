
<template>
  <div>
    <Drawer
        v-model="drawer_edit"
        width="550"
        :mask-closable="true"
    >
        <tournamentEdit :game_id="game_id" :tournament_id="edit_id" @closePage="closeEditDrawer"></tournamentEdit>
    </Drawer>  
    <Row>
        <Col  style="text-align: left">
            <Input v-model="search_key" placeholder="搜索名称/id" search @on-search="onSearch" />
        </Col>
        <Col  style="text-align: left">
            <RadioGroup v-model="status_id" type="button">
                <Radio label='-1' border>全部</Radio>
                <Radio label='1' border>未开始</Radio>
                <Radio label='2' border>进行中</Radio>
                <Radio label='3' border>已结束</Radio>
            </RadioGroup>
        </Col>
        <Col >
          <!-- <DatePicker format="yyyy-MM-dd" :value="date"  placeholder="选择日期" @on-change="date=$event" style="width: 200px"></DatePicker> -->
        </Col>
        <Col >
            <Button type="success"  @click="add">新增</Button>
        </Col>
    </Row>
    <fitTable :columns="columns" :data="data" :loading="loading"></fitTable>
    <Page
        :total="total_page"
        :current="current_page"
        :page-size="page_size"
        @on-change="onPageChange"
        show-total
        show-elevator
      ></Page>
  </div>
</template>

<script>

import { List } from '@/api/games/tournament';
import moment from 'moment';
import fitTable from '../../basic/table.vue';
import {getImgGameUrl} from '@/utils/util';
import tournamentEdit from './edit.vue';

const logButton = (vm, h, params) => {
  return h(
    'Button',
    {
      props: {
        type: 'success',
      },
      on: {
        click: () => {
          let query = { tournament_id: params.row.id, game_id: vm.game_id }
          // let resolve_data = vm.$router.resolve({ path: '/games/tournament/boards', query: query })
          // window.open(resolve_data.href, '_blank')
          let game = vm.$conf.games.games[vm.game_id];
          if (game){
              let path = '/games/'+ game.name_en +'/tournament/boards';
              let resolve_data = vm.$router.resolve({ path: path, query: query })
              window.open(resolve_data.href, '_blank')
          }else{
              vm.$Message.error("暂不支持的运动类型")
          }
        }
      }
    },
    '赛况'
  )
}

const resultButton = (vm, h, params) => {
  return h(
    'Button',
    {
      props: {
        type: 'warning',
      },
      on: {
        click: () => {
          let query = { tournament_id: params.row.id, game_id: vm.game_id }
          // let resolve_data = vm.$router.resolve({ path: '/games/tournament/results', query: query })
          // window.open(resolve_data.href, '_blank')
          let game = vm.$conf.games.games[vm.game_id];
          if (game){
              let path = '/games/'+ game.name_en +'/tournament/results';
              let resolve_data = vm.$router.resolve({ path: path, query: query })
              window.open(resolve_data.href, '_blank')
          }else{
              vm.$Message.error("暂不支持的运动类型")
          }
        }
      }
    },
    '排名'
  )
}

const editButton = (vm, h, params) => {
  // let self = this;
    return h(
        'Button',
        {
            style: {
                // margin: '2px 2px'
            },
            props: {
                // type: 'info',
            },
            on: {
                click: () => {
                    vm.edit_id = params.row.id;
                    vm.drawer_edit = true;
                }
            }
        },
        '编辑'
    )
}

export default {
  props: {
      game_id: Number,   // 运动id
  },
  data () {
    let self = (window.x = this)
    return {
      modal_report: false,
      date: '',
      status_id: '-1',
      search_key: '',
      loading: false,
      total_page: 0,
      page_size: 0,
      current_page: 1,
      columns: [
        {
          title: 'id',
          width: '100',
          key: 'id'
        },
        {
          title: '中文全称',
          minWidth: 80,
          key: 'name_zh',
        },
        {
          title: '中文简称',
          minWidth: 80,
          key: 'abbr_zh',
        },
        {
          title: '英文简称',
          minWidth: 80,
          key: 'name_en',
        },
        {
          title: '英文简称',
          minWidth: 80,
          key: 'abbr_en',
        },

        {
          title: '开始时间',
          width: 100,
          key: 'start_date',
          render: function (h, params) {
            let currentRow = params.row
            return h(
              'span',
              moment.unix(currentRow.start_date).format('YYYY-MM-DD HH:mm')
            )
          }
        },

        {
          title: '结束时间',
          width: '100',
          key: 'end_date',
          render: function (h, params) {
            let currentRow = params.row
            return h(
              'span',
              moment.unix(currentRow.end_date).format('YYYY-MM-DD HH:mm')
            )
          }
        },

        {
          title: 'logo',
          minWidth: 80,
          key: 'logo',
          align: 'center',
          render: function (h, params) {
            let currentRow = params.row
            return h('img', {
              attrs: {
                src: getImgGameUrl(self.game_id, 'tournament', currentRow.logo),
              },
              class: {'tournament-logo': true} 
            })
          }
        },

        {
          title: '封面',
          minWidth: 80,
          key: 'cover',
          align: 'center',
          render: function (h, params) {
            let currentRow = params.row
            return h('img', {
              attrs: {
                src: getImgGameUrl(self.game_id, 'tournament/cover', currentRow.cover),
              },
              class: {'tournament-logo': true} 
            })
          }
        },

        {
          title: '状态',
          width: '80',
          key: 'status_id',
          render: function (h, params) {
            let currentRow = params.row
            return h(
              'span', currentRow.status_id == 1 ? '未开始' : (currentRow.status_id == 2 ? '进行中' : currentRow.status_id == 3 ? '已结束' : '未知')
            )
          }
        },

        {
          title: '操作',
          key: 'deleted',
          align: 'center',
          width: 200,
          render (h, params) {
            return [logButton(self, h, params), resultButton(self, h, params), editButton(self, h, params),]
          }
        }

      ],
      data: [],

      drawer_edit: false,
      edit_id: 0,
    }
  },
  components: {
    // TournamentFinder
    fitTable,
    tournamentEdit,
  },
  methods: {
    onPageChange (page) {
      this.current_page = page
      this.getData()
    },
    onSearch (search_key) {
      this.current_page = 1
      this.search_key = search_key
      this.getData()
    },
    getData () {
      let self = this
      // console.log(process.env.NODE_ENV);

      this.loading = true

      // if(this.date){
      //     var date = moment( this.date).unix();
      // }else{
      //     var date = 0;
      // }

      var params = {
        game_id: self.game_id,
        status_id: self.status_id,
        page: self.current_page,
        // date: date,
        search_key: self.search_key,
      }
      List(params).then(response => {
        if (response.data.code == 0) {
          this.data = response.data.data.list;
          this.total_page = response.data.data.total_page;
          this.page_size = response.data.data.page_size;
          this.current_page = response.data.data.current_page;
          this.loading = false;
        } else {
          this.$Message.error(response.data.msg);
        }
      })
    },
    // end
      closeEditDrawer(){
          let self = this;
          self.drawer_edit = false;
          self.edit_id = 0;
          self.getData();
      },
    // end
    add () {
        this.edit_id = 0;
        this.drawer_edit = true
    },
    // end
  },
  mounted () {
    this.getData()
  },
  watch: {
    game_id (value) {
      let self = this
      // console.log(value)
      self.getData()
    },
    status_id (value) {
      let self = this
      // console.log(value)
      self.getData()
    },
    date (value) {
      let self = this
      self.getData()
    }

  },
  computed: {}
}
</script>

<style scoped>
    @import "list.css"
</style>
