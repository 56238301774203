import http from '@/utils/http';


export const searchMap = (parameter) => {
  return http.request({
    url: '/v1/games/map/search',
    method: 'post',
    data: parameter
  })
}
