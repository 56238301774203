<template>
    <Row class="flex flex-nowrap" >
        <!-- {{ value }}
        {{ v }} -->
        <Poptip v-if="value" trigger="hover" content="手动">
            <Button @click="onClick(0)" type="error" size="small" icon="md-lock"  ></Button>
        </Poptip>
        <Poptip v-else trigger="hover" content="自动">
            <Button @click="onClick(1)" type="success" size="small" icon="md-unlock" ></Button>
        </Poptip>
    </Row>
</template>
<script>
// 
import {updateDataLock} from '@/api/datalock'

export default {
    name: 'dataLock',
    props: {
        'data_type': Number,
        'sport_id': Number,
        "data_id": Number,
        "k": String,
        "v": Number
    },
    data () {
        return {
            value: 0,
        }
    },
    mounted(){
        this.value = this.v
    },
    watch:{
        v(val){
            this.value=val;
        },
        'data_id': {
            handler(value){
                let self = this;
                self.value = self.v
            },
            deep: false,
	        immediate: false,  // 深度触发
        },
        'sport_id': {
            handler(value){
                let self = this;
                self.value = self.v
            },
            deep: false,
	        immediate: false,  // 深度触发
        },
    },
    methods: {
        onClick(val){
            let self = this
            // 请求接口更新数据
            let data_dic = {
                "datatype": self.data_type,
                sport_id: self.sport_id,
                data_id: self.data_id,
                params: {
                    [self.k]: val,
                }
            }
            updateDataLock(data_dic).then(response => {
                if (response.data.code == 0) {
                    this.value = val
                    this.$Message.success("成功");
                } else {
                    this.$Message.error(response.data.msg);
                }
            })
        }
    }
}
</script>
